<template>
  <div class="my-garden position-static">
    <div class="d-flex justify-content-center align-items-center">
        <JayaTitle
            classType="page-title"
            moreInfo="Retrouvez ici les informations propres à votre jardin : votre coin spécial potager, les plantes et
          tâches à faire, son score de biodiversité. Vous pouvez modifier son nom, et ses caractéristiques (balcon...)
          sur l'icône d'édition à droite."
            titleName="Mon Jardin"/>

      <b-dropdown
          v-if="this.$store.state.garden.gardens.length > 1"
          id="garden-choice"
          no-caret
          toggle-class="p-0"
          variant="flat-primary">
        <template #button-content>
          <feather-icon
              icon="ListIcon"
              size="24"/>
        </template>
        <b-dropdown-item
            v-for="(garden, index) in this.$store.state.garden.gardens"
            :key="index"
            class="p-0">
          <b-row no-gutters>
            <b-col cols="2">
              <b-button
                  :class="garden.is_active ? 'garden-delete-button' : ''"
                  class="p-1"
                  variant="flat-primary"
                  @click="updatePromptDelete(garden)">
                <feather-icon
                    class="text-primary"
                    icon="Edit2Icon"
                    size="18"/>
              </b-button>
            </b-col>
            <b-col
                :class="garden.is_active ? 'selected-garden' : ''"
                class="align-self-center text-truncate"
                @click="setMainGarden(garden)">
              <span class="font-weight-bold">{{ garden.name }}</span>
              <br>
              <span>
              {{ garden.local_authority_infos ? ' ' + garden.local_authority_infos.name : '' }}
              </span>
            </b-col>
          </b-row>
        </b-dropdown-item>
      </b-dropdown>
      <b-button
          v-else
          class="p-0"
          variant="flat-primary"
          @click="updatePromptDelete($store.state.garden.gardens[0])">
        <feather-icon
            class="text-primary"
            icon="Edit2Icon"
            size="20"/>
      </b-button>
    </div>
  <div class="d-flex justify-content-center align-items-center" v-if="this.gardens.length > 1">
      <span>{{ this.gardens.find(garden => garden.is_active).name }}</span>
  </div>

    <!-- Tabs Container -->
    <div class="relative">
      <b-tabs :key="tabsKey" ref="gardenTabs" fill @input="verifyPlantIdButton" v-model="gardenTabIndex">
        <b-tab class="content" :active="gardenTabIndex===0" title="Mon Potager">
          <VGardenList/>
        </b-tab>
        <b-tab v-if="getPlants" :active="gardenTabIndex===1" title="Plantes">
          <MyGardenOnboarding v-if="getPlants.length===0" @biodiv="openBiodivTab" v-on:idPlant="checkPermissions"/>
          <div v-else class="max-w-md" style="margin-bottom: 125px;">
            <div v-if="parsePlantsWithAction(getPlants).length > 0">
              <jaya-divider color="primary" text="A faire en ce moment"/>

              <PlantCard
                  v-for="(plant) in parsePlantsWithAction(getPlants)"
                  :key="plant.id"
                  :gardenPlantId="plant.id"
                  :plant-photos="plant.plant.photos"
                  :plant-pictos="plant.plant.pictos"
                  :plantId="plant.plant.id"
                  :plantNotifications="getPlantNotifications(plant.plant, plant.vegetable_garden_plant)"
                  :quantity="plant.count"
                  :subtitle="plant.plant.scientific_name"
                  :title="plant.plant.common_name"
                  gardenPlant/>

              <jaya-divider color="primary"/>
            </div>

            <PlantCard
                v-for="(plant) in parsePlantsWithoutAction(getPlants)"
                :key="plant.id"
                :gardenPlantId="plant.id"
                :plant-photos="plant.plant.photos"
                :plant-pictos="plant.plant.pictos"
                :plantId="plant.plant.id"
                :plantNotifications="getPlantNotifications(plant.plant, plant.vegetable_garden_plant)"
                :quantity="plant.count"
                :subtitle="plant.plant.scientific_name"
                :title="plant.plant.common_name"
                gardenPlant/>
          </div>
        </b-tab>
        <b-tab class="content" :active="gardenTabIndex===2">
          <template #title>
            <b-spinner v-if="getAddBiodiversityButton" small type="grow" variant="warning"/>
            <div>Biodiversité</div>
          </template>
          <div v-if="isBiodiversityScoreDone" class="w-100">
            <b-card
                class="max-w-md w-100">
              <b-card-title>
                <JayaTooltip class="mb-1" title="Indicateur de biodiversité">
                  <h3 class="mb-0">Indicateur de biodiversité
                    <feather-icon
                        icon="InfoIcon"
                        class="text-info mb-25"
                        size="20"/>
                  </h3>
                  <div slot="text">
                    <p> Issu d'un travail doctoral sur la place des habitants et de leur jardin dans la prise en charge de
                      la biodiversité, cet indicateur mesure la Biodiversité Potentielle dans les Jardins privés.<br/>
                      C'est un outil utile pour simplement, mais efficacement identifier les meilleurs leviers que vous pouvez
                      entreprendre pour améliorer la biodiversité.
                    </p>
                    <i> Réference : Riboulot-Chetrit Mathilde, "Les habitants et leur jardin.
                      Relations au vivant, pratiques de jardinage et biodiversité au c&oelig;ur de
                      l'agglomération parisienne"</i>
                  </div>
                </JayaTooltip>
              </b-card-title>
              <div v-if="gardenTabIndex === 2">
                <vue-apex-charts
                    :options="biodiversityScoreRadialBar.chartOptions"
                    :series="biodiversityScoreRadialBar.series"
                    height=210
                    type="radialBar"
                    class="mb-2"
                    ref="apexchart"
                    @click="promptBiodiversityScore=true"/>
              </div>
              <p class="text-primary w-100 text-center"> Partager avec ma résidence
                <feather-icon class="cursor-pointer text-dark"
                  icon="SendIcon"
                  size="21"/></p>
                              <p>{{ scoreAnalysis }}</p>
                              <TextBlock v-if="helpSheets.length > 0" class="mt-2" title="Nos conseils"/>
                              <div v-for="(advices, index) in biodiversityAdvices" v-bind:key="index" class="advices">
                                <p>{{ advices }}</p>
                              </div>
                            </b-card>

                            <app-collapse>
                              <app-collapse-item
                                  v-for="sheet in orderedHelpSheets"
                                  :key="sheet.id"
                                  :title="'Fiche ' + sheet.id + ' : ' + sheet.title "
                                  class="max-w-md mb-50 border">
                                <HelpSheet :sheet="sheet"/>
                              </app-collapse-item>
                            </app-collapse>
                          </div>
                          <b-card v-else class="max-w-xl text-center">
                            <h5 class="mb-2">Évaluer la biodiversité potentielle de votre jardin en répondant à quelques
                              questions</h5>
                            <b-button size="lg" variant="primary" @click="popUpBiodiversityScore=true">
                              Calculer son indicateur de biodiversité
                            </b-button>
                            <div class="text-justify text-black mt-3 max-w-sm rounded-lg">
                              <p> Issu d'un travail doctoral sur la place des habitants et de leur jardin dans la prise en charge de la
                                biodiversité, cet indicateur mesure la Biodiversité Potentielle dans les Jardins privés.<br/>
                                C'est un outil utile pour simplement, mais efficacement identifier les meilleurs leviers que vous pouvez
                                entreprendre pour améliorer la biodiversité.
                              </p>
                              <i> Réference : Riboulot-Chetrit Mathilde, "Les habitants et leur jardin. Relations au
                                vivant, pratiques de jardinage et biodiversité au c&oelig;ur de l'agglomération parisienne"</i>
                            </div>
                          </b-card>
                        </b-tab>
                      </b-tabs>
                    </div>

                    <b-modal
                        ref="promptBiodiversityScore"
                        v-model="promptBiodiversityScore"
                        cancel-title="Annuler"
                        centered
                        ok-title="Refaire le test"
                        title="Recalculer son score"
                        variant="primary"
                        @ok="popUpBiodiversityScore=true">
                      <p> Votre situation a changé ? Vous avez réaménagé votre jardin ? N'hésitez pas à recalculer votre score pour
                        qu'il représente mieux votre cas !</p>
                    </b-modal>

                    <b-modal
                        ref="popUpBiodiversityScore"
                        v-model="popUpBiodiversityScore"
                        body-class="px-0"
                        centered
                        hide-footer
                        title="Calcul du score de biodiversité"
                        variant="primary">
                      <BiodiversityQuiz @quiz-error="quizError" @quiz-finished="quizFinised"/>
                    </b-modal>

                    <b-modal
                        v-model="popUpIdPlant"
                        centered
                        hide-footer
                        title="Identifier une plante"
                        variant="primary">
                      <PlantIdentification :iosCameraAuthorized="iosCameraAuthorized"/>
                    </b-modal>

                    <validation-observer>
                      <b-modal
                          v-model="editGardenPrompt"
                          :ok-disabled="validEditGarden"
                          cancel-title="Annuler"
                          centered
                          ok-title="Valider"
                          title="Modification du jardin"
                          variant="primary"
                          @ok="editGarden">

                        <b-form-group label="Nom de votre jardin :">
                          <validation-provider v-slot="{errors}" name="Nom du jardin" rules="required|max:50">
                            <b-form-input
                                v-model="gardenName"
                                name="email"/>
                            <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
                          </validation-provider>
                        </b-form-group>

                        <b-form-group>
                          <label >
                            Votre jardin est-il sur un balcon ?
                          </label>
                          <b-form-radio-group v-model="gardenBalcony" class="mb-1">
                            <b-form-radio name="balcony-radios" value="balcony">Oui</b-form-radio>
                            <b-form-radio name="balcony-radios" value="nonbalcony">Non</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>

                        <div v-if="!deleteGardenPrompt && this.$store.state.garden.gardens.length > 1"
                             class="d-flex justify-content-center">
                          <b-button
                              variant="danger"
                              @click="deleteGardenPrompt=true">
                            <feather-icon
                                icon="TrashIcon"
                                size="16"/>
                            <span class="align-middle ml-50">Supprimer le jardin</span>
                          </b-button>
                        </div>

                        <div v-if="deleteGardenPrompt" class="p-50 rounded-lg border-solid border-2 border-danger mt-2">
                          <div class="text-danger d-flex justify-content-center">
                            <feather-icon class=" text-danger mr-50" icon="AlertTriangleIcon" size="16"/>
                            <span class="font-weight-bolder">Voulez-vous vraiment supprimer ce jardin ?</span>
                          </div>
                          <p class="text-justify pt-50">La suppression d'un jardin est définitive. Si vous décidez de le supprimer, il
                            vous sera impossible de le récupérer.</p>
                          <div class="d-flex justify-content-around">
                            <b-button
                                size="sm"
                                variant="info"
                                @click="deleteGardenPrompt=false">
                              Non
                            </b-button>
                            <b-button
                                size="sm"
                                variant="danger"
                                @click="deleteGarden">
                              Oui
                            </b-button>
                          </div>
                        </div>
                      </b-modal>
                    </validation-observer>
                  </div>
                </template>

                <script>
                import {
                  BButton,
                  BCard,
                  BCardTitle,
                  BCol,
                  BDropdown,
                  BDropdownItem,
                  BFormRadioGroup,
                  BFormRadio,
                  BFormGroup,
                  BFormInput,
                  BFormText,
                  BModal,
                  BRow,
                  BSpinner,
                  BTab,
                  BTabs,
                } from 'bootstrap-vue'
                import Ripple from 'vue-ripple-directive'
                import JayaTitle from '@/components/global/JayaTitle.vue'
                import MyGardenOnboarding from '@/components/garden/MyGardenOnboarding.vue'
                import PlantCard from '@/components/plants/PlantCard.vue'
                import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
                import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
                import TextBlock from '@/components/global/JayaTextBlock.vue'
                import VGardenList from '@/components/vgarden/VGardenList.vue'
                import _ from 'lodash'
                import HelpSheet from '@/components/help-sheets/HelpSheet.vue'
                import VueApexCharts from 'vue-apexcharts'
                import PlantIdentification from '@/components/plants/PlantIdentification.vue'
                import BiodiversityQuiz from '@/components/garden/biodiversite/BiodiversityQuiz.vue'
                import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
                import { max, required } from 'vee-validate/dist/rules'

                extend('required', {...required, message: 'Ce champ est requis'});
                extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

                export default {
                  components: {
                    BDropdown,
                    BDropdownItem,
                    BFormRadioGroup,
                    BFormRadio,
                    BButton,
                    BTabs,
                    BTab,
                    BSpinner,
                    BCard,
                    BCardTitle,
                    BModal,
                    BFormInput,
                    BFormGroup,
                    BFormText,
                    BRow,
                    BCol,
                    AppCollapse,
                    AppCollapseItem,
                    JayaTitle,
                    MyGardenOnboarding,
                    PlantCard,
                    TextBlock,
                    VGardenList,
                    HelpSheet,
                    VueApexCharts,
                    PlantIdentification,
                    BiodiversityQuiz,
                    ValidationObserver,
                    ValidationProvider,
                  },
                  directives: {
                    Ripple,
                  },
                  data() {
                    return {
                      promptBiodiversityScore: false,
                      popUpBiodiversityScore: false,
                      deleteGardenPrompt: false,
                      editGardenPrompt: false,
                      gardenName: "",
                      gardenBalcony: null,
                      popUpIdPlant: false,
                      activeGardenName: "Mon Jardin",
                      biodiversityAdvices: [],
                      scoreAnalysis: "",
                      helpSheets: [],
                      gardenToEdit: null,
                      biodiversityScoreRadialBar: {
                        series: [],
                        chartOptions: {
                          plotOptions: {
                            radialBar: {
                              startAngle: -150,
                              endAngle: 150,
                              hollow: {
                                size: '70%',
                              },
                              dataLabels: {
                                name: {
                                  show: false
                                },
                                value: {
                                  offsetY: 14,
                                  color: '#99a2ac',
                                  fontSize: '3rem'
                                }
                              }
                            }
                          },
                          colors: ['var(--primary)'],
                          stroke: {
                            lineCap: 'round'
                          }
                        }
                      },
                      iosCameraAuthorized: true,
                      tabsKey: 0,
                      gardenTabIndex: 1,
                      addBiodiversityButton: true
                    }
                  },
                  mounted() {
                    if (this.$route.hash === '') {
                      //used for popups
                      this.$router.push({path: this.path, hash: 'jardin'})
                    }

                    this.$store.dispatch("feedback/getScore", {
                      garden: this.$store.state.garden.gardens.find(garden => {
                        return garden.is_active
                      })["id"]
                    })
                        .then(response => {
                          let biodiversity_infos = response.data[0]
                          if (biodiversity_infos.sheets.length) {
                            this.$store.dispatch("helpSheet/search", {search: biodiversity_infos.sheets})
                                .then(response_sheets => {
                                  var sheets = response_sheets.data
                                  for (const sheet of sheets) {
                                    this.$store.dispatch("helpSheet/get", sheet.id)
                                        .then(response_sheet => {
                                          this.helpSheets.push(response_sheet.data)
                                        })
                                  }
                                })
                                .catch(() => {
                                  this.helpSheets = []
                                })
                          }
                          let score = biodiversity_infos.score * 100
                          score = score.toPrecision(2)
                          this.biodiversityScoreRadialBar.series.push(score)
                          this.scoreAnalysis = biodiversity_infos.global_comment
                          this.biodiversityAdvices = biodiversity_infos.advices
                        })
                        .catch(() => {
                          this.biodiversityScoreRadialBar.series.push(-1)
                        })
                    setTimeout(() => {
                      this.addBiodiversityButton = false
                    }, 5000)

                    this.verifyPlantIdButton()
                  },
                  computed: {
                    gardens() {
                      return this.$store.state.garden.gardens
                    },
                    getPlants() {
                      let plants = {}
                      try {
                        plants = this.$store.state.garden.gardens.find(gd => {
                          return gd.is_active
                        })["plants"]
                      } catch {
                        return null
                      }
                      return plants
                    },
                    orderedHelpSheets() {
                      return _.orderBy(this.helpSheets, 'id')
                    },
                    isBiodiversityScoreDone() {
                      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                      this.tabsKey = this.tabsKey + 1
                      return this.biodiversityScoreRadialBar.series[0] > 0
                    },
                    validEditGarden() {
                      return !(this.gardenName !== '' && this.gardenName.length <= 50)
                    },
                    getAddBiodiversityButton() {
                      return !this.$store.state.garden.gardens.find(garden=>{return garden.is_active}).biodiv_done && this.addBiodiversityButton
                    }
                  },
                  methods: {
                    quizFinised() {
                      this.biodiversityScoreRadialBar.series = []
                      this.$store.dispatch("feedback/getScore", {
                        garden: this.$store.state.garden.gardens.find(garden => {
                          return garden.is_active
                        })["id"]
                      })
                          .then(response => {
                            let biodiversity_infos = response.data[0]
                            if (biodiversity_infos.sheets.length) {
                              this.$store.dispatch("helpSheet/search", {search: biodiversity_infos.sheets})
                                  .then(response_sheets => {
                                    var sheets = response_sheets.data
                                    this.helpSheets = []
                                    for (const sheet of sheets) {
                                      this.$store.dispatch("helpSheet/get", sheet.id)
                                          .then(response_sheet => {
                                            this.helpSheets.push(response_sheet.data)
                                          })
                                    }
                                    this.helpSheets = _.orderBy(this.helpSheets, 'number')
                                  })
                                  .catch(() => {
                                    this.helpSheets = []
                                  })
                            } else {
                              this.helpSheets = []
                            }
                            let score = biodiversity_infos.score * 100
                            score = score.toPrecision(2)
                            this.biodiversityScoreRadialBar.series.push(score)
                            this.scoreAnalysis = biodiversity_infos.global_comment
                            this.biodiversityAdvices = biodiversity_infos.advices
                          })
                          .catch(() => {
                            this.biodiversityScoreRadialBar.series.push(-1)
                            this.scoreAnalysis = ""
                            this.biodiversityAdvices = []
                          })
                      this.popUpBiodiversityScore = false
                      if (!this.$store.state.garden.gardens.find(garden=>{return garden.is_active}).biodiv_done) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.tabsKey = this.tabsKey + 1
                        this.addBiodiversityButton = false
                      }
                    },
                    quizError() {
                      this.popUpBiodiversityScore = false
                      this.$notify({
                        time: 4000,
                        color: 'danger',
                        title: 'Erreur',
                        text: 'Votre score n\'a pas pu être mis à jour.'
                      })
                    },
                    parsePlantsWithAction(plantArray) {
                      const resultArray = [];
                      let i;
                      if (plantArray) {
                        for (i = 0; i < plantArray.length; i++) {
                          if ((this.getTrim(plantArray[i].plant) || this.getHarvest(plantArray[i].plant))) {
                            let plantsInstances = plantArray.filter(item => {
                              return item.plant.id === plantArray[i].plant.id
                            })
                            let plantValue = plantArray[i]
                            plantValue.quantity = plantsInstances.length
                            resultArray.push(plantValue)
                          }
                        }
                      }
                      return _.orderBy(resultArray, 'plant.common_name')
                    },
                    parsePlantsWithoutAction(plantArray) {
                      const resultArray = [];
                      let i;
                      if (plantArray) {
                        for (i = 0; i < plantArray.length; i++) {
                          if (!this.getTrim(plantArray[i].plant) && !this.getHarvest(plantArray[i].plant)) {
                            let plantsInstances = plantArray.filter(item => {
                              return item.plant.id === plantArray[i].plant.id
                            })
                            let plantValue = plantArray[i]
                            plantValue.quantity = plantsInstances.length
                            resultArray.push(plantValue)
                          }
                        }
                      }
                      return _.orderBy(resultArray, 'plant.common_name')
                    },
                    getPlantNotifications(plantObject, vgarden) {
                      return {
                        trim: this.getTrim(plantObject),
                        harvest: this.getHarvest(plantObject),
                        local: plantObject.local,
                        invasive: plantObject.invasive,
                        vgarden: !!vgarden
                      }
                    },
                    getTrim(plantObject) {
                      if (plantObject.rf_infos && plantObject.rf_infos.trims) {
                        for (let trim of plantObject.rf_infos.trims) {
                          for (let period of trim.periods) {
                            let startList = period.start_date.split('/')
                            let endList = period.end_date.split('/')
                            let tday = new Date()
                            let startDate = new Date(tday.getFullYear() + "-" + startList[1] + "-" + startList[0])
                            let endDate = new Date(tday.getFullYear() + "-" + endList[1] + "-" + endList[0])
                            //make sure trim is not all year around
                            if (startDate.getMonth() - tday.getMonth() <= 0 && endDate.getMonth() - tday.getMonth() >= 0 && (endDate.getMonth() - startDate.getMonth() < 11)) {
                              return true
                            }
                          }
                        }
                      }
                      return false
                    },
                    getHarvest(plantObject) {
                      if (plantObject.rf_infos && plantObject.rf_infos.organs) {
                        for (let organ of plantObject.rf_infos.organs) {
                          for (let period of organ.harvest_periods) {
                            let startList = period.start_date.split('/')
                            let endList = period.end_date.split('/')
                            let tday = new Date()
                            let startDate = new Date(tday.getFullYear() + "-" + startList[1] + "-" + startList[0])
                            let endDate = new Date(tday.getFullYear() + "-" + endList[1] + "-" + endList[0])
                            //make sure harvest is not all year around
                            if (startDate.getMonth() - tday.getMonth() <= 0 && endDate.getMonth() - tday.getMonth() >= 0 && (endDate.getMonth() - startDate.getMonth() < 11)) {
                              return true
                            }
                          }
                        }
                      }
                      return false
                    },
                    setMainGarden(garden) {
                      if (!garden.is_active) {
                        this.$loading(true)
                        let active_garden = this.$store.state.garden.gardens.find(gd => {
                          return gd.is_active
                        })
                        this.$store.dispatch("garden/updateGarden", {id: active_garden.id, is_active: false})
                            .then(() => {
                              this.$store.dispatch("garden/updateGarden", {id: garden.id, is_active: true})
                                  .then(() => {
                                    if (garden.local_authority_infos && this.$store.state.account.user.color_adaptation) {
                                      let localAuthority = garden.local_authority_infos
                                      localStorage.baseline = localAuthority.baseline

                                      let colors = this.$setColors(this.$store.state.account.user.color_adaptation, localAuthority)
                                      this.$store.commit("localAuthority/SET_COLOR", colors)

                                      this.$store.commit("localAuthority/SET_TRANSPENT_HEADER",
                                          this.$store.state.account.user.color_adaptation && localAuthority &&
                                          localAuthority.transparent_header)

                                      if (localAuthority.logo_url) {
                                        this.$store.commit("localAuthority/SET_LOGO_URL", localAuthority.logo_url)
                                      }
                                    }
                                    this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", "update_score")
                                    this.$loading(false)
                                  })
                                  .catch(() => {
                                    this.$notify({
                                      time: 4000,
                                      color: 'danger',
                                      title: 'Une erreur est survenue',
                                      text: 'Un problème est survenu lors de l\'édition du jardin, veuillez réessayer plus tard'
                                    })
                                    this.$loading(false)
                                  })
                            })
                            .catch(() => {
                              this.$notify({
                                time: 4000,
                                color: 'danger',
                                title: 'Une erreur est survenue',
                                text: 'Un problème est survenu lors de l\'édition du jardin, veuillez réessayer plus tard'
                              })
                              this.$loading(false)
                            })
                      }
                    },
                    updatePromptDelete(garden) {
                      this.gardenToEdit = garden.id
                      this.gardenName = garden.name
                      this.gardenBalcony = garden.is_balcony
                      this.editGardenPrompt = true

                      if (garden.is_balcony === true) {
                        this.gardenBalcony = 'balcony'
                      } else if (this.gardenBalcony === false) {
                        this.gardenBalcony = 'nonbalcony'
                      }
                    },
                    editGarden() {
                      let payload = {
                        id: this.gardenToEdit,
                        name: this.gardenName,
                      }

                      if (this.gardenBalcony === 'balcony') {
                        payload['is_balcony'] = true
                      } else if (this.gardenBalcony === 'nonbalcony') {
                        payload['is_balcony'] = false
                      } else {
                        payload['is_balcony'] = null
                      }

                      this.$store.dispatch("garden/updateGarden", payload)
                          .then(() => {
                            this.$notify({
                              title: "Jardin mis à jour",
                              color: "primary",
                              text: "Votre jardin a bien été mis à jour",
                              time: 4000
                            })
                            this.editGardenPrompt = false
                            this.deleteGardenPrompt = false
                          })
                          .catch(() => {
                            this.$notify({
                              title: "Une erreur est survenue",
                              color: "danger",
                              text: "Une erreur est survenue lors de la mise à jour de votre jardin, veuillez rééssayer ultérieurement",
                              time: 4000
                            })
                            this.editGardenPrompt = false
                            this.deleteGardenPrompt = false
                          })
                    },
                    deleteGarden() {
                      this.$store.dispatch("garden/updateGarden", {id: this.gardenToEdit, state: 'DE'})
                          .then(() => {
                            this.$notify({
                              title: "Jardin supprimé",
                              color: "primary",
                              text: "Votre jardin a bien été supprimé",
                              time: 4000
                            })
                            this.editGardenPrompt = false
                            this.deleteGardenPrompt = false
                          })
                          .catch(() => {
                            this.$notify({
                              title: "Une erreur est survenue",
                              color: "danger",
                              text: "Une erreur est survenue lors de la suppression de votre jardin, veuillez rééssayer ultérieurement",
                              time: 4000
                            })
                            this.editGardenPrompt = false
                            this.deleteGardenPrompt = false
                          })
                    },
                    checkPermissions() {
                      if (window.cordova && window.cordova.platformId == "ios") {
                        let thisVue = this
                        window.cordova.plugins.diagnostic.getCameraAuthorizationStatus({
                          successCallback: function (status) {
                            if (status === window.cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED) {
                              window.cordova.plugins.diagnostic.requestCameraAuthorization({
                                successCallback: function (statusRequest) {
                                  if (statusRequest == window.cordova.plugins.diagnostic.permissionStatus.GRANTED) {
                                    thisVue.iosCameraAuthorized = true
                                    thisVue.popUpIdPlant = true
                                  } else {
                                    thisVue.iosCameraAuthorized = false
                                    thisVue.popUpIdPlant = true
                                  }
                                },
                                externalStorage: false
                              })
                            } else if (status === window.cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS || status === window.cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE) {
                              thisVue.iosCameraAuthorized = false
                              thisVue.popUpIdPlant = true
                            } else {
                              thisVue.iosCameraAuthorized = true
                              thisVue.popUpIdPlant = true
                            }
                          },
                          externalStorage: false
                        })
                      } else {
                        this.popUpIdPlant = true
                      }
                    },
                    openSettings() {
                      window.cordova.plugins.diagnostic.switchToSettings()
                    },
                    openBiodivTab() {
                      window.scrollTo(0, 0)
                      this.$refs.gardenTabs.activate(2)
                    },
                    showPlantIdentificationButton() {
                      this.$emit('myGardenShowElements',)
                    },
                    hidePlantIdentificationButton() {
                      this.$emit('myGardenHideElements')
                    },
                    verifyPlantIdButton() {
                      if (this.$refs.gardenTabs.currentTab !== 1 || (this.getPlants && this.getPlants.length === 0)) {
                        this.hidePlantIdentificationButton()
                      } else {
                        this.showPlantIdentificationButton()
                      }
                    }
                  },
                  watch: {
                    '$store.state.feedback.updateBiodivScore'(value) {
                      if (value === "update_score") {
                        this.quizFinised()
                        this.$store.commit("feedback/SET_UPDATE_BIODIV_SCORE", "update_questions")
                      }
                    },
                    getPlants() {
                      this.verifyPlantIdButton()
                    }
                  }
                }
                </script>

                <style lang="scss">
                .my-garden {
                  width: 100%;
                  min-height: 300px;

                  .dropdown-item {
                    padding: 0;
                  }

                  .selected-garden {
                    background: white !important;
                    color: var(--primary) !important;
                  }

                  .content {
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 1rem;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    align-self: center;
                  }

                  .sub-button {
                    width: 99%;
                    font-size: 15px;
                  }
                }

                .result-swiper {
                  .swiper-pagination-bullet-active {
                    background: #fff !important;
                    opacity: 1 !important;
                    border: solid black 1px;
                  }

                  .swiper-pagination-bullet {
                    background: gray;
                    opacity: 0.7;
                    border: solid #111 1px;
                  }
                }

                .max-h-20 {
                  max-height: 20rem;
                }

                .garden-delete-button {
                  border: 1px solid white !important
                }
                </style>

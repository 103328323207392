<template>
  <div class="encyclopedia w-100">
    <JayaTitle classType="page-title" moreInfo="Apprenez ici les caractéristiques détaillées de nombreuses espèces et leurs possibles interactions avec la biodiversité." titleName="Encyclopedie"/>
    <b-tabs ref="questionTabs" fill>
      <b-tab title="Plantes">
        <PlantSearch ref="plantSearch"/>
      </b-tab>
      <b-tab title="Faune">
        <div class="plant-card cursor-pointer" v-for="animal in $store.state.plant.animals" :key="animal.id" @click="openPopUp(animal.id)">
          <b-card class="overflow-hidden mb-25" no-body>
            <b-row class="plant-card-height" no-gutters>
              <b-col class="jaya-card-image" cols="4" style="background-color: var(--secondary-light)">
                <b-card-img
                    :alt="'photo de' +  animal.common_name"
                    class="jaya-card-image"
                    :src="animal.img.url"
                    left/>
              </b-col>
              <b-col class="d-flex align-items-center pl-1 plant-card-height" cols="8">
                <b-card-body class="p-0 pr-1 text-truncate">
                  <b-card-text class="m-0">
                    <h4 class="m-0 font-weight-bolder text-truncate">{{ animal.commmon_name }}</h4>
                    <div class="text-truncate">{{ animal.scientific_name }}</div>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <JayaPopUp :ref="'plantInfoPopUp'+animal.id" name="FichePlante">
            <b-card :key=" 'popupcard'+ animal.id" class="plant-info max-w-xl" noBody>
              <div class="jaya-top-row p-1">
                <viewer ref="viewer" :images="[animal.img.url]" class="jaya-container">
                  <img
                    :alt="'photo de' +  animal.common_name"
                    :src="animal.img.url"
                    class="jaya-image">
                </viewer>
              </div>
              <b-card-body>
                <b-row class="mb-1">
                  <div class="ml-1">
                    <span class="font-medium-5 font-weight-bolder"> {{ animal.commmon_name }} </span><br>
                    <i class="font-medium-2"> {{ animal.scientific_name }} </i> <br>
                  </div>
                </b-row>
                <b-alert
                    class="py-1 m-1 w-100"
                    show
                    variant="primary">
                  <b-row class="text-center align-items-center px-1">
                    <b-col cols="2">
                      <img alt="Icon plant local" src="@/assets/images/icons/other/location.svg" style="height: 50px;">
                    </b-col>
                    <b-col>
                      Cette espèce peut-être observée sur et autour de votre résidence. Ouvrez l'oeil  !
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col>
                      Vous l'avez observée ?  Faites le savoir aux résidents en partageant un post sur le Forum en cliquant ici.
                    </b-col>
                    <b-col cols="2">
                      <feather-icon
                        icon="MessageSquareIcon"
                        size="24"/>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1" v-if="animal.url_citizen_science">
                    <b-col cols="2" class="d-flex justify-content-center align-items-center">
                      <a :href="animal.url_citizen_science" target="_blank">
                        <feather-icon
                            icon="CameraIcon"
                            size="24"
                            class="text-secondary"
                        />
                      </a>
                    </b-col>
                    <b-col>
                      Pour vous impliquer davantage sur les observations des {{ animal.type }}, rendez vous <a :href="animal.url_citizen_science" target="_blank">ici</a>
                    </b-col>

                  </b-row>
                </b-alert>
                <div class="w-100">
                  <TextBlock title="description">
                    {{animal.description}}
                  </TextBlock>
                </div>
              </b-card-body>
            </b-card>

          </JayaPopUp>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import JayaPopUp from "@/components/global/JayaPopUp.vue"
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {BAlert, BCard, BCardBody, BCardImg, BCardText, BCol, BRow, BTab, BTabs} from "bootstrap-vue";

export default {
  name: 'Encyclopedia',
  components: {
    JayaTitle,
    PlantSearch,
    JayaPopUp,
    TextBlock,
    BAlert,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BCol,
    BRow,
    BTab,
    BTabs
  },
  data() {
    return {
      animals: []
    }
  },
  mounted() {
    this.animals = []
  },
  methods: {
    scroll(event) {
      this.$refs.plantSearch.scroll(event)
    },
    openPopUp(animalId) {
      this.$refs['plantInfoPopUp'+ animalId][0].openPopUp()
    }
  }
}
</script>

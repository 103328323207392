<template>
  <b-card class="mx-auto" style="max-width: 50rem;">
    <h3 class="d-flex justify-content-center mb-2">Bienvenue sur Hortilio</h3>
    <div class="text-center">Accédez rapidement aux fonctionnalités principales :</div>
    <div class="w-100 ">
      <b-row class="justify-content-center align-items-top mt-1">
        <feather-icon
            class="text-primary mr-50"
            icon="HomeIcon"
            size="18"/>
        <h4>Accueil</h4>
      </b-row>

      <b-row class="justify-content-center">
        <b-button
            size="lg"
            variant="relief-primary"
            @click="$router.push({name: 'new-vgarden'})">
          Créer une planche potagère
        </b-button>
      </b-row>

      <b-row class="justify-content-center mt-1">
        <b-button
            size="lg"
            variant="relief-primary"
            @click="$emit('biodiv')">
          Evaluer la biodiversité
        </b-button>
      </b-row>

      <b-row class="justify-content-center mt-1">
        <b-button
            size="lg"
            variant="relief-primary"
            @click="$emit('idPlant')">
          Identifier une plante
        </b-button>
      </b-row>

      <b-row class="justify-content-center align-items-top mt-1">
        <feather-icon
            class="text-primary mr-50"
            icon="MessageCircleIcon"
            size="18"/>
        <h4>Forum</h4>
      </b-row>

      <b-row class="justify-content-center">
        <b-button
            size="lg"
            variant="relief-primary"
            @click="goToForum">
          Poser une question
        </b-button>
      </b-row>

      <b-row class="justify-content-center align-items-top mt-1">
        <feather-icon
            class="text-primary mr-50"
            icon="BookOpenIcon"
            size="18"/>
        <h4>Encyclopédie</h4>
      </b-row>

      <b-row class="justify-content-center">
        <b-button
            size="lg"
            variant="relief-primary"
            @click="goToEncyclopedia">
          Découvrir des plantes
        </b-button>
      </b-row>
    </div>

    <jaya-divider :thickness="1" color="light"/>

    <div class="text-center mt-1">
      Si vous voulez découvrir en détail le fonctionnement de cette application, nous vous invitons à lire la page
      <JayaTooltip text="Un résumé des fonctions et des objectifs du projet, un guide pour mieux se servir de l'application">
        Guide
      </JayaTooltip>
      :
    </div>
    <b-row class="justify-content-center mt-1">
      <b-button
          size="lg"
          variant="relief-primary"
          @click="$router.push('/guide')">
        Page Guide
      </b-button>
    </b-row>
  </b-card>
</template>

<script>
import {BButton, BCard, BCardText, BRow} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
  },
  methods: {
    goToEncyclopedia() {
      this.$router.push({name: 'jayaccueil', hash: '#encyclopedie'})
    }
    ,
    goToForum() {
      this.$router.push({name: 'jayaccueil', hash: '#forum'})
    }
  }
}
</script>
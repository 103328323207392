<template>
  <div class="plant-identification">
    <b-row class="justify-content-around align-items-center mt-1" no-gutters>
      <div v-if="!plantIdentificationResults" class="d-flex justify-content-center w-100">
        <img alt="Logo PlantNet" class="mb-1" src="@/assets/images/logo/partenaires/plantnet.svg" style="height: 50px;">
      </div>
      <p v-if="!plantPhoto && iosCameraAuthorized" class="m-1">Hortilio vous propose d'indentifier les plantes de votre
        jardin à l'aide des
        services du projet Pl@ntnet.
        Pour cela rien de plus simple: prenez une photo de la plante que vous souhaitez identifier (de préférence une
        feuille ou une fleur) et envoyez la nous.
      </p>
      <p v-if="!iosCameraAuthorized" class="m-1">
        L'identification de plante repose sur des photos. Pour donner l'accès à la caméra à Hortilio, rendez vous dans
        vos <span class="text-primary" @click="openSettings">paramètres</span>
      </p>
      <viewer v-if="plantPhoto" :images="[plantPhoto.url]" class="flex justify-center w-full mb-5">
        <img
            v-for="src in [plantPhoto.url]"
            :key="src"
            :src="src"
            :style="plantIdentificationResults ? 'max-height: 100px;' : 'max-height: 250px;'"
            alt="photo plante"
            style="max-width: 100%; max-height: 250px;">
      </viewer>
      <b-button
          :disabled="!iosCameraAuthorized"
          variant="relief-primary"
          @click="promptUploadPhoto = true">
        <feather-icon
            class="mr-50"
            icon="ImageIcon"
            size="16"/>
        <span class="align-middle">
          <span v-if="!plantPhoto">Sélectionner / prendre une photo</span>
          <span v-else>Changer de photo</span>
        </span>
      </b-button>
    </b-row>

    <div v-if="plantPhoto" class="mt-50">
      <p class="mb-0"> Que représente la photo ? </p>
      <select v-model="trait" class="mb-1 w-100">
        <option v-for="(item, index) in possibleTraits" :key="index">{{ item }}</option>
      </select>
    </div>

    <b-row v-if="plantPhoto" class="justify-content-center mt-1" no-gutters>
      <b-button
          :disabled="!validIdentification"
          variant="primary"
          @click="identifyPlant">
        <feather-icon
            class="mr-50"
            icon="SearchIcon"
            size="16"/>
        <span class="align-middle">Identifier</span>
      </b-button>
    </b-row>

    <jaya-divider v-if="plantIdentificationResults" :thickness="1" color="danger"/>

    <div v-if="plantIdentificationResults" class="mt-1">
      <p v-if="!postedOnForum" class="font-italic">
        Aucun des résultats ci-dessous ne vous semble convenir ? Demandez de l'aide aux autres
        jardiniers Hortilio !
      </p>
      <p v-if="postedOnForum"
         class="font-italic text-info text-center">
        Vous venez de demander de l'aide aux autres jardiniers sur le forum !
      </p>
      <div v-if="!postedOnForum"
           class="d-flex justify-content-center">
        <b-button
            :disabled="!validIdentification"
            variant="secondary"
            @click="questionsPost">
          <feather-icon
              class="mr-50"
              icon="MessageCircleIcon"
              size="16"/>
          <span class="align-middle">Poster sur le forum</span>
        </b-button>
      </div>
    </div>

    <jaya-divider v-if="plantIdentificationResults" :thickness="1" color="danger"/>

    <div v-if="plantIdentificationResults" class="mt-1">
      <div v-for="(item, index) in plantIdentificationResults.plantnet_results" :key="index">
        <div class="swipers">
          <p v-if="item.species.commonNames.length" class="font-weight-bold">
            {{ item.species.commonNames[0] }}
          </p>
          <p class="font-italic">
            {{ item.species.scientificNameWithoutAuthor }}
          </p>
          <swiper
              ref="swipers"
              :options="swiperOptionM"
              class="swiper result-swiper w-100">
            <swiper-slide
                v-for="(itemImage, indexImage) in item.images"
                :key="indexImage">
              <div class="position-relative">
                <div class="position-absolute top-0 w-100 text-white" style="background-color: rgba(0, 0, 0, 0.3);">
                  <div class="d-flex justify-content-around">
                    {{ itemImage.citation }}
                    <JayaTooltip class="text-black font-weight-bolder" color="var(--dark)" text="Ce pourcentage représente la confiance en ce résultat." title="Score">
                      {{ Math.round(item.score * 100) }}%
                    </JayaTooltip>
                  </div>
                </div>
                <div class="w-100 d-flex justify-content-center align-items-center">
                  <viewer :images="[itemImage.url.o]">
                    <img
                        v-for="src in [itemImage.url.o]"
                        :key="src"
                        :src="src"
                        alt="Photo de plant"
                        class="mw-100 max-h-20">
                  </viewer>
                </div>
              </div>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination"></div>
          </swiper>
          <div class="d-flex justify-content-center">
            <b-button
                v-if="item.jaya_id"
                variant="primary"
                @click="setPlantId(item.jaya_id)">
              <feather-icon
                  class="mr-50"
                  icon="ChevronsRightIcon"
                  size="16"/>
              <span class="align-middle">Ouvrir la fiche Hortilio</span>
            </b-button>
          </div>
        </div>
        <jaya-divider :thickness="1" color="primary"/>
      </div>
    </div>

    <JayUpload :active.sync="promptUploadPhoto"
        title="Changer de photo"
        @on-cancel="plantPhoto = null"
        @on-error="plantPhoto = null"
        @on-success="addPhoto"/>

    <b-modal
        v-model="promptPlant"
        centered
        hide-footer
        title="Informations détaillées"
        variant="primary">
      <PlantInfo v-if="promptPlant" :plantId="dbPlantId"/>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BModal, BRow} from "bootstrap-vue";
import JayUpload from "@/components/global/JayUpload";
import PlantInfo from "@/components/plants/PlantInfo.vue"
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import 'swiper/css/swiper.css'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    JayUpload,
    PlantInfo,
    Swiper,
    SwiperSlide,
  },
  props: {
    iosCameraAuthorized: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      possibleTraits: ['Fleur', 'Feuille', 'Fruit', 'Ecorce'],
      traitsEquivalence: {Fleur: 'flower', Feuille: 'leaf', Fruit: 'fruit', Ecorce: 'bark'},
      trait: null,
      postedOnForum: false,
      promptPlant: false,
      plantIdentificationResults: null,
      plantPhoto: null,
      promptUploadPhoto: false,
      dbPlantId: null,
      swiperOptionM: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
    }
  },
  computed: {
    validIdentification() {
      return this.plantPhoto && this.trait
    },
  },
  methods: {
    addPhoto(photo) {
      this.plantPhoto = photo
    },
    setPlantId(plantId) {
      this.dbPlantId = plantId
      this.promptPlant = true
    },
    identifyPlant() {
      let photos = [this.plantPhoto.id]
      let organs = [this.traitsEquivalence[this.trait]]
      let payload = {photos: photos, organs: organs}
      this.$loading(true)
      this.$store.dispatch('plantIdentification/postIdentification', payload)
          .then(response => {
            this.plantIdentificationResults = response.data
            this.$loading(false)
          })
          .catch(() => {
            this.$loading(false)
            this.$notify({
              time: 4000,
              color: 'danger',
              title: 'Une erreur est survenue',
              text: 'Un problème est survenu lors de la demande d\'identification, veuillez réessayer plus tard'
            })
          })
    },
    questionsPost() {
      let payload = {
        type: "PI",
        photo: this.plantPhoto.id,
        title: "Identification de Plante",
        details: "Je cherche le nom de cette plante. J'ai essayé de l'identifier via plantnet mais n'ai pas eu de réponse satisfaisante. Pourriez-vous m'aider s'il vous plait ?"
      }

      this.$store.dispatch('forum/postQuestion', payload)
          .then((response) => {
            let plantIdentificationPayload = {id: this.plantIdentificationResults.id, post: response.data.id}
            this.$store.dispatch('plantIdentification/updateIdentification', plantIdentificationPayload)
                .then(() => {
                  this.$notify({
                    title: 'Question envoyée',
                    text: ' Votre question a bien été postée sur le forum',
                    color: 'primary',
                    time: 4000
                  })
                  this.postedOnForum = true
                })
          })
    },
  }
}
</script>

<style lang="scss">
</style>

<template>
  <b-card :class="subscribedCard" class="mb-0"
      noBody
      style="max-width:19rem; min-width:19rem; min-height:21rem; max-height:21rem;">
    <b-card-body class="d-flex flex-column justify-content-between position-relative border-top-light">
      <b-button
          v-if="isMember"
          class="position-absolute position-right-0 position-top-0 m-50"
          size="sm"
          variant="info"
          @click="administrate">
        <feather-icon
            class="mr-25"
            icon="SlidersIcon"/>
        <span class="align-middle">Administrer</span>
      </b-button>
      <img :alt="getImageDescription" :src="getImage" class="card-img-top pb-50"
          style="object-fit: contain; max-height:10.5rem; min-height:10.5rem">
      <b-card-title
          class="d-flex justify-content-center align-items-center pb-50 m-0 text-center font-weight-bolder text-break overflow-hidden"
          style="max-height:4rem; min-height:4rem">
        {{ localAuthority.name }}
      </b-card-title>
      <b-card-footer class="p-0 pt-1"
          style="max-height:4rem; min-height:4rem">
        <b-row align-h="center" class="w-100" no-gutters>
          <b-col class="text-center col-4 d-flex justify-content-center align-items-center">
            <feather-icon
                v-b-hover="infoButtonHoverHandle"
                :class="infoButtonHover ? 'text-secondary' : 'text-info'"
                class="cursor-pointer"
                icon="InfoIcon"
                size="28"
                @click="popUpMoreInfo = true"/>
          </b-col>
          <b-col v-if="!isMainLocalAuthority" class="text-center col-8">
            <b-button
                :variant="isSubscribed ? 'secondary' : 'primary'"
                size="sm"
                @click="isSubscribed ? unsubscribe(localAuthority) : subscribePopUp = true">
              <feather-icon
                  :class="{'fill-current': isSubscribed}"
                  class="mr-25 fill-primary"
                  icon="HeartIcon"/>
              <span class="align-middle">
                {{ isSubscribed ? "Se désabonner" : "S'abonner" }}
             </span>
            </b-button>
          </b-col>
          <b-col v-else class="text-center col-8">
            <JayaTooltip :title="localAuthority.name"
                         class="text-primary font-weight-bolder"
                         color="info"
                         text="Ceci est votre collectivité territoriale principale correspondant à votre code postal.
                          Vous y êtes automatiquement abonné. ">
              <feather-icon
                  class="mr-25"
                  icon="HomeIcon"/>
              <span>
                Abonné par défaut
              </span>
            </JayaTooltip>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card-body>

    <b-modal
        v-model="subscribePopUp"
        body-class="text-center"
        centered
        hide-footer
        title="Nouvel abonnement">
      <h4> Pourquoi souhaitez-vous vous abonner à {{ localAuthority.name }} ?</h4>

      <b-row align-h="center" align-v="center" class="w-100 my-1" no-gutters
             v-if="!this.$store.state.garden.gardens.find(garden => garden.is_active===true).local_authority">
        <b-button
            class="col flex-grow-1 p-50"
            variant="outline-primary"
            @click="performSubscription(localAuthority, false, true)">
          Mon jardin
          <span class="font-italic">
            "{{this.$store.state.garden.gardens.find(garden => garden.is_active).name}}"
          </span>
          est dans cette collectivité
        </b-button>
        <JayaTooltip title="Info abonnement"
                     class="text-primary font-weight-bolder col text-right flex-grow-0 pl-1"
                     color="info"
                     text="En cliquant sur ce bouton, vous vous abonnez à la collectivité: vous aurez accès aux évènements,
                      lieux et fiches pratiques qu'elle partage. En plus, votre jardin actuel sera rattaché à cette collectivité.
                      Cela signifie que les pratiques et contenus de votre jardin seront inclus dans les statistiques anonymisées de cette collectivité.">
          <feather-icon
              size="20"
              icon="HelpCircleIcon"/>
        </JayaTooltip>
      </b-row>

      <b-row align-h="center" align-v="center" class="w-100 my-1" no-gutters
             v-if="this.$store.state.garden.gardens.length<8">
        <b-button
            class="col flex-grow-1 p-50"
            variant="outline-primary"
            @click="performSubscription(localAuthority, true)">
          Je veux créer un nouveau jardin rattaché à cette collectivité
          <div v-if="hasGardenInLocalAuthority" class="font-italic my-50">
            Pour information, vous avez déjà un jardin dans cette collectivité : "
            {{this.$store.state.garden.gardens.find(gd => {
            return gd.local_authority === this.localAuthority.id
          }).name}}"
          </div>
        </b-button>
        <JayaTooltip title="Info abonnement"
                     class="text-primary font-weight-bolder col text-right flex-grow-0 pl-1"
                     color="info"
                     text="En cliquant sur ce bouton, vous vous abonnez à la collectivité: vous aurez accès aux évènements,
                      lieux et fiches pratiques qu'elle partage. En plus, un nouveau jardin sera crée et rattaché à cette collectivité.
                      Cela signifie que les pratiques et contenus de ce jardin seront inclus dans les statistiques anonymisées de cette collectivité.">
          <feather-icon
              size="20"
              icon="HelpCircleIcon"/>
        </JayaTooltip>
      </b-row>

      <b-row align-h="between" align-v="center" class="w-100 my-1" no-gutters>
        <b-button
            class="col flex-grow-1 p-50"
            variant="outline-primary"
            @click="performSubscription(localAuthority)">
          Par curiosité
        </b-button>
        <JayaTooltip title="Info abonnement"
                     class="text-primary font-weight-bolder col text-right flex-grow-0 pl-1"
                     color="info"
                     text="En cliquant sur ce bouton, vous vous abonnez à la collectivité: vous aurez accès aux évènements,
                      lieux et fiches pratiques qu'elle partage.">
          <feather-icon
              size="20"
              icon="HelpCircleIcon"/>
        </JayaTooltip>
      </b-row>
    </b-modal>

    <b-modal
        v-model="unSubscribePopUp"
        body-class="text-center"
        centered
        hide-footer
        title="Se désabonner">
      <h4>Pourquoi souhaitez-vous vous désabonner de {{ localAuthority.name }} ?</h4>
      <b-row align-h="between" align-v="center" class="w-100 my-1" no-gutters
             v-if="hasGardenInLocalAuthority">
        <b-button
            class="col flex-grow-1 p-50"
            variant="outline-primary"
            @click="performUnsubscription(localAuthority, change=false, remove_garden=true)">
          Mon jardin "{{this.$store.state.garden.gardens.find(gd => gd.local_authority === localAuthority.id).name}}" n'est plus dans cette collectivité
        </b-button>
        <JayaTooltip title="Info abonnement"
                     class="text-primary font-weight-bolder col text-right flex-grow-0 pl-1"
                     color="info"
                     text="En cliquant sur ce bouton, vous vous désabonnez de la collectivité: vous n'aurez plus accès aux évènements,
                    lieux et fiches pratiques qu'elle partage. En plus, votre jardin sera détaché de cette collectivité.
                    Cela signifie que les pratiques et contenus de votre jardin ne seront plus inclus dans les statistiques anonymisées de cette collectivité.">
          <feather-icon
              size="20"
              icon="HelpCircleIcon"/>
        </JayaTooltip>
      </b-row>

      <b-row align-h="between" align-v="center" class="w-100 my-1" no-gutters>
        <b-button
            class="col flex-grow-1 p-50"
            variant="outline-primary"
            @click="performUnsubscription(localAuthority)">
          Je ne veux plus voir les communications de cette collectivité
        </b-button>
        <JayaTooltip title="Info abonnement"
                     class="text-primary font-weight-bolder col text-right flex-grow-0 pl-1"
                     color="info"
                     text="En cliquant sur ce bouton, vous vous désabonnez de la collectivité: vous n'aurez plus accès aux évènements,
                    lieux et fiches pratiques qu'elle partage.">
          <feather-icon
              size="20"
              icon="HelpCircleIcon"/>
        </JayaTooltip>
      </b-row>
    </b-modal>

    <b-modal
        ref="popUp"
        v-model="popUpMoreInfo"
        :title="localAuthority.name"
        body-class="pt-0"
        centered
        hide-footer
        scrollable>
      <LocalAuthorityInfo :localAuthority="localAuthority"/>
    </b-modal>
  </b-card>
</template>

<script type="text/javascript">
import AccountSettings from '@/components/user/AccountSettings.vue';
import LocalAuthorityInfo from "@/components/local-authorities/LocalAuthorityInfo";
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BOverlay,
  BRow
} from "bootstrap-vue";

export default {
  name: "local-authority-card",
  components: {
    name,
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BModal,
    AccountSettings,
    BCardFooter,
    BRow,
    BCol,
    BOverlay,
    LocalAuthorityInfo,
  },
  data() {
    return {
      popUpMoreInfo: false,
      subscribePopUp: false,
      unSubscribePopUp: false,
      infoButtonHover: false,
    }
  },
  props: {
    localAuthority: {
      type: Object,
      default: null
    }
  },
  computed: {
    getImage() {
      return this.localAuthority.photo_url
          ? process.env.VUE_APP_BACKEND_URL + '/..' + this.localAuthority.photo_url + "&size=960"
          : require('@/assets/images/default_pictures/local_authority_default.jpg')
    },
    getImageDescription() {
      return "Image de " + this.title
    },
    isMember() {
      if (this.$store.state.localAuthority.memberOf == null) {
        return false
      }
      return this.$store.state.localAuthority.memberOf.id === this.localAuthority.id;
    },
    isSubscribed() {
      if (this.$store.state.localAuthority.subscriptions == null) {
        return false
      }
      return this.$store.state.localAuthority.subscriptions.some(localAuthority => localAuthority.id === this.localAuthority.id);
    },
    isMainLocalAuthority() {
      // Check that the user's zip code is in the zipcodes of the local auth
      return this.localAuthority.zip_codes.includes(this.$store.state.account.user.zip_code)
    },
    hasGardenInLocalAuthority() {
      return this.$store.state.garden.gardens.some(gd => {
        return gd.local_authority === this.localAuthority.id
      })
    },
    checkUserCanMove() {
      return this.localAuthority.zip_codes.includes(this.$store.state.account.user.zip_code)
    },
    subscribedCard() {
      if (this.isMainLocalAuthority) {
        return 'selected-card'
      }
      if (this.isSubscribed) {
        return 'selected-card selected-card-secondary'
      }
      return ''
    }
  },
  methods: {

    performSubscription(localAuthority, create_garden = false, change_zip = false) {
      return new Promise((resolve, reject) => {
        this.$loading(true)
        let params = {id: localAuthority.id, create_garden: create_garden, change_zip: change_zip}
        if (change_zip) {
          params.garden_id = this.$store.state.garden.gardens.find(garden => {
            return garden.is_active
          }).id
        }
        this.$store.dispatch("localAuthority/subscribe", params)
            .then(() => {
              this.$notify({
                time: 4000,
                title: "Abonnement",
                color: "primary",
                text: "Vous êtes maintenant abonné à " + localAuthority.name
              })
              if (change_zip || create_garden) {
                this.$store.dispatch("garden/fetchGardens")
                    .then(() => {

                      localStorage.baseline = localAuthority.baseline

                      let colors = this.$setColors(this.$store.state.account.user.color_adaptation, localAuthority)
                      this.$store.commit("localAuthority/SET_COLOR", colors)

                      this.$store.commit("localAuthority/SET_TRANSPENT_HEADER",
                          this.$store.state.account.user.color_adaptation && localAuthority &&
                          localAuthority.transparent_header)

                      if (localAuthority.logo_url) {
                        this.$store.commit("localAuthority/SET_LOGO_URL", localAuthority.logo_url)
                      }

                      this.$loading(false)
                      this.subscribePopUp = false
                    })
              } else {
                this.$loading(false)
                this.subscribePopUp = false
              }
              resolve()
            }).catch((error) => {
          if (error.response && error.response.status === 403 && error.response.data.detail === "Can't have more than 8 gardens") {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Nombre de jardins trop important",
              text: "Vous disposez déjà de 8 jardins, veuillez en supprimer un pour pouvoir vous abonner"
            })
          } else {
            this.$notify({
              time: 4000,
              color: "danger",
              title: "Erreur",
              text: "Une erreur est survenue, veuillez réessayer plus tard"
            })
          }
          this.$loading(false)
          this.subscribePopUp = false
          reject()
        })
      })
    },

    performUnsubscription(localAuthority, change = false, remove_garden = false) {
      let params = {id: localAuthority.id}
      if (remove_garden) {
        params.remove_garden = true
        params.garden_id = this.$store.state.garden.gardens.find(garden => {
          return garden.local_authority === localAuthority.id
        }).id
      }
      return new Promise((resolve, reject) => {
        this.$store.dispatch("localAuthority/unsubscribe", params).then(() => {
          this.$notify({
            time: 4000,
            title: "Abonnement",
            color: "danger",
            text: "Vous n'êtes désormais plus abonné à " + localAuthority.name
          })
          this.$store.dispatch("garden/fetchGardens")
              .then(() => {
                this.unSubscribePopUp = false
                let active_garden = this.$store.state.garden.gardens.find(garden => {
                  return garden.is_active
                })
                let garden_local_auth = this.$store.state.localAuthority.subscriptions.find(la => {
                  return la.id === active_garden.local_authority
                })

                localStorage.baseline = garden_local_auth ? garden_local_auth.baseline : ""

                let colors = this.$setColors(this.$store.state.account.user.color_adaptation, garden_local_auth)
                this.$store.commit("localAuthority/SET_COLOR", colors)

                this.$store.commit("localAuthority/SET_TRANSPENT_HEADER",
                    this.$store.state.account.user.color_adaptation && garden_local_auth &&
                    garden_local_auth.transparent_header)

                this.$store.commit("localAuthority/SET_LOGO_URL",
                    garden_local_auth && garden_local_auth.photo_url ? garden_local_auth.photo_url : null)

                resolve()
              })
              .catch(() => {
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Erreur",
                  text: "Une erreur est survenue, veuillez réessayer plus tard"
                })
                reject()
              })
        }).catch(() => {
          this.$notify({
            time: 4000,
            color: "danger",
            title: "Erreur",
            text: "Une erreur est survenue, veuillez réessayer plus tard"
          })
          reject()
        })
      })
    },
    unsubscribe() {
      this.unSubscribePopUp = true
    },

    administrate() {
      this.$router.push('/local-authorities/admin')
    },

    infoButtonHoverHandle(val) {
      this.infoButtonHover = val
    }
  }
}
</script>

<style lang="scss" media="screen">

</style>
